import $ from 'jquery'; // quiet
import 'bootstrap' // quiet

// Anchor link smooth scrolling
$('a[href*="#"]:not([href="#"])').click(function(){if(location.pathname.replace(/^\//,"")==this.pathname.replace(/^\//,"")&&location.hostname==this.hostname){var t=$(this.hash);if((t=t.length?t:$("[name="+this.hash.slice(1)+"]")).length)return $("html, body").animate({scrollTop:t.offset().top},750),!1}});

//// MODAL
var modal;
function set_modal(type,title,body) {
    modal.find('.modal-content').removeClass().addClass('modal-content '+type);
    if(title !=='') {
        modal.find('.modal-title').html(title);
    }
    if(body !=='') {
        modal.find('.modal-body').html(body);
    }
}
function show_modal(type,title,body) {
    set_modal(type,title,body);
    modal.modal('show');
}

//// FORMS

var field,
    field_block;

// On page load
$(function(){
    $(".field input:-webkit-autofill, .field textarea:-webkit-autofill").closest(".field").addClass("focus");
});

// When field is focussed
$(document).on("focus", ".input,.textarea", function() {
    field = $(this);
    field_block = field.closest(".field");
    if(!field.prop('disabled'))
    {
        field_block.addClass("focus");
    }
});
// When field loses focus
$(document).on("blur", ".input,.textarea", function() {
    field = $(this);
    field_block = field.closest(".field");
    if(field.val().length === 0) {
        field_block.removeClass("focus");
    }
});

// Submissions
var btn,
    form,
    formdata;

$(document).on("submit", ".tr_form", function(e) {
  e.preventDefault();
  form = $(this);
  btn = form.find('button[type=submit]');
  modal = form.find('.modal');

  //Capture new form data
  formdata = form.serializeArray();
  formdata.push({
      "name": "submit",
      "value": 1
  });

  $(".formdata").each(function(){
    if($(this).attr("type") === 'checkbox')
    {
      if($(this).prop("checked") === true)
      {
        formdata.push({
            "name": this.name,
            "value": $(this).val()
        });
      }
    }
    else
    {
      formdata.push({
          "name": this.name,
          "value": $(this).val()
      });
    }
  });

  //Disable the save button and add "waiting" status
  btn.prop("disabled",true).addClass("running");
  //Remove error styling on all fields
  form.find(".field input,.field textarea").removeClass("is-invalid").closest(".field").find('.invalid-feedback').remove();


  //Save the data
  if(formdata.length) {
    $.ajax({
      type: "POST",
      url: "/ajax/form_process.php",
      data: formdata,
      dataType: "json"
    })
    .done(function(data) {
      if(data.success)
      {
        //Enable save button
        setTimeout(function(){
          btn.prop("disabled",false).removeClass("running");
        }, 1000);

        //Display success message
        show_modal('success','Message sent',data.success);

        //Reset the form
        form.trigger("reset");
        form.find('.field').removeClass("focus");
      }
      else
      {
        if(!$.isEmptyObject(data.errors))
        {
          $.each(data.errors,function(i,val) {
            form.find(".field [name="+i+"]").addClass("is-invalid")
                                    .closest(".field").append('<p class="invalid-feedback">'+val+'</p>');
          });
          show_modal('error','We found some errors','There was a problem submitting this form.  Please correct the marked fields');
        }
      }
    })
    .fail(function() {
        show_modal('error','Form failure','A technical error stopped this form from submitting. <br>Please contact us for assistance');
    })
    .always(function() {
      //Enable save button
      setTimeout(function(){
        btn.prop("disabled",false).removeClass("running");
      }, 1000);
    });
  }
});
